<div class="hf-card"
    [ngClass]="{'hf-primary-card': cardStyle === 'primary', 'hf-secondary-card': cardStyle === 'secondary', 'hf-tertiary-card': cardStyle === 'tertiary'}">
    @if(cardTitle !== '') {
    <span class="card-title hf-dark-font" [ngClass]="{'hf-card-title-extralarge': cardTitleSize === 'extralarge',
        'hf-card-title-large': cardTitleSize === 'large',
        'hf-card-title-medium': cardTitleSize === 'medium',
        'hf-card-title-small': cardTitleSize === 'small',
        'hf-card-title-extrasmall': cardTitleSize === 'extrasmall'}">{{cardTitle}}</span>
    }
    <div class="hf-card-content" [ngClass]="{'hf-margin-top': cardTitle !== ''}">
        <ng-content></ng-content>
    </div>
</div>