import { Component, OnDestroy, OnInit } from '@angular/core';
import { HfToastService, ToastMessage } from './hf-toast.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'hf-toast',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './hf-toast.component.html',
  styleUrl: './hf-toast.component.scss'
})
export class HfToastComponent implements OnInit, OnDestroy {

  toastDataSub: Subscription;
  toastTimeOut: any;
  showToast = false;
  toastTitle: string;
  toastMessage: string;
  toastType: 'success' | 'warning' | 'error' | 'info' = 'info';

  constructor(
    private hfToastService: HfToastService
  ) { }

  ngOnInit() {
    this.getToastData();
  }

  getToastData() {
    this.toastDataSub = this.hfToastService.toastMessage$.subscribe((data: ToastMessage) => {
      if (data.title !== '') {
        this.toastTitle = data.title;
        this.toastMessage = data.msg;
        this.toastType = data.type;
        this.showToast = true;
        this.toastTimeOut = setTimeout(() => {
          this.hideToast();
        }, 5000);
      }
    })
  }

  hideToast() {
    this.showToast = false;
  }

  ngOnDestroy() {
    if (this.toastDataSub) {
      this.toastDataSub.unsubscribe();
    }
    clearTimeout(this.toastTimeOut);
  }

}
