import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FirebaseMessaging, PermissionStatus, GetTokenResult } from '@capacitor-firebase/messaging';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NotificationService {

  fcmToken: string = '';
  private notificationSubscription: Subscription | null = null;

  constructor(
    private http: HttpClient,
  ) { }

  initNotificationService() {
    FirebaseMessaging.checkPermissions().then((result: PermissionStatus) => {
      if (result.receive !== 'granted') {
        FirebaseMessaging.requestPermissions().then((result: PermissionStatus) => {
          if (result.receive === 'granted') {
            this.addNotificationListener();
          }
        }).catch((error) => {
          console.log("error in FirebaseMessaging requestPermissions", error);
        });
      } else {
        this.addNotificationListener();
      }
    }).catch((error) => {
      console.log("error in FirebaseMessaging checkPermissions", error);
    });
  }

  sendFcmTokenToServer(baseApiUrl: string) {
    const url = baseApiUrl + 'push-notification/subscribe';

    this.notificationSubscription = this.http.post(url, { Token: String(this.fcmToken) }).subscribe({
      next: (res) => {
        console.log("success to send fcm token to server", res);
      },
      error: (err) => {
        console.log("Error to send fcm token to server", JSON.stringify(err));
      }
    });
  }

  removeFcmTokenToServer(baseApiUrl: string) {
    const url = baseApiUrl + 'push-notification/unsubscribe';
    this.notificationSubscription = this.http.post(url, { Token: String(this.fcmToken) }).subscribe({
      next: (res) => {
        console.log("success to removed fcm token from server", res);
      },
      error: (err) => {
        console.log("Error to removed fcm token from server", JSON.stringify(err));
      }
    });
  }

  private addNotificationListener() {
    FirebaseMessaging.getToken().then((result: GetTokenResult) => {
      this.fcmToken = result.token;
      if (this.fcmToken && this.fcmToken !== '') {
        FirebaseMessaging.addListener("notificationReceived", (event) => {
          console.log("notificationReceived now: ", JSON.stringify(event));
        });
      }
    }).catch((error) => {
      console.log("error in addNotificationListener FirebaseMessaging getToken", error);
    });
  }
}
