import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[phoneNumberFormatter]',
  standalone: true
})
export class PhoneNumberFormatterDirective {

  constructor(
    private el: ElementRef
  ) {
  }

  ngAfterViewInit() {
    this.formatPhoneNumber(this.el.nativeElement.querySelector('input').value);
  }

  @HostListener('input', ['$event.target.value'])
  onInput(value: string) {
    this.formatPhoneNumber(value);
  }

  formatPhoneNumber(value: string = '') {
    value = value.replace(/\D/g, ''); // Remove non-numeric characters

    let formatted = '';
    for (let i = 0; i < value.length; i++) {
      if (i === 0 && value !== '') {
        formatted = '(' + value[i];
      } else if (i === 3) {
        formatted += ') ' + value[i];
      } else if (i === 6) {
        formatted += '-' + value[i];
      } else {
        formatted += value[i];
      }
    }

    const maxLength = 14;

    if (formatted.length <= maxLength) {
      this.el.nativeElement.querySelector('input').value = formatted;
    }
  }
}
