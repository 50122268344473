import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'currencyInnerhtml',
  standalone: true,
})
export class CurrencyInnerhtmlPipe implements PipeTransform {
  transform(value: number, mode: 'full' | 'short' = 'short'): string {
    const formattedValue = value.toFixed(3);
    const [whole, decimal] = formattedValue.split('.');
    
    const mainDecimal = decimal.slice(0, 2);
    const lastDigit = decimal[2];

    if (mode === 'short') {
      return `<sup class="dollar">$</sup>${whole}.${mainDecimal}`;
    }

    return `<sup class="dollar">$</sup>${whole}.${mainDecimal}<sup class="last-digit">${lastDigit}</sup>`;
  }
}
