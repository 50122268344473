<!-- <div class="hf-radio">
    
    @if (!isDisabled) {
    <input class="hf-radio-input cursor-pointer" type="radio" [ngClass]="{
        'hf-radio-input-large': radioSize == 'large',
        'hf-radio-input-medium': radioSize == 'medium',
        'hf-radio-input-small': radioSize == 'small'}" [name]="inputName" [id]="inputId" [value]="radioValue"
        [formControl]="inputValue">
    } @else {
    <input class="hf-radio-input cursor-not-allowed" type="radio" [ngClass]="{
        'hf-radio-input-large': radioSize == 'large',
        'hf-radio-input-medium': radioSize == 'medium',
        'hf-radio-input-small': radioSize == 'small'}" [name]="inputName" [id]="inputId" [value]="radioValue" disabled>
    }
    
    @if (inputLabel && inputId !== '') {
    <label [ngClass]="{'hf-radio-label-large': radioSize == 'large',
        'hf-radio-label-medium': radioSize == 'medium',
        'hf-radio-label-small': radioSize == 'small',
        'cursor-not-allowed hf-radio-label-disabled': isDisabled, 'cursor-pointer': !isDisabled}"
        [for]="inputId">{{inputLabel}}</label>
    }
</div> -->

<div class="hf-radio">
    <label class="hf-radio-label" [ngClass]="{'hf-radio-label-large': radioSize == 'large',
        'hf-radio-label-medium': radioSize == 'medium',
        'hf-radio-label-small': radioSize == 'small',
        'cursor-not-allowed hf-radio-label-disabled': isDisabled, 'cursor-pointer': !isDisabled}">

        @if (!isDisabled) {
        <input class="hf-radio-input" type="radio" [ngClass]="{
        'hf-radio-input-large': radioSize == 'large',
        'hf-radio-input-medium': radioSize == 'medium',
        'hf-radio-input-small': radioSize == 'small'}" [name]="inputName" [value]="radioValue"
            [formControl]="inputValue">
        } @else {
        <input class="hf-radio-input" type="radio" [ngClass]="{
                'hf-radio-input-large': radioSize == 'large',
                'hf-radio-input-medium': radioSize == 'medium',
                'hf-radio-input-small': radioSize == 'small'}" [name]="inputName" [value]="radioValue" disabled>
        }

        {{inputLabel}}
    </label>
</div>