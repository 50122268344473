import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'hf-price',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './hf-price.component.html',
  styleUrl: './hf-price.component.scss'
})
export class HfPriceComponent implements OnInit, OnChanges {

  @Input() amount: any = '1.002';
  @Input() prefixSize: string = '12px';
  @Input() prefixHeight: string = '16px';
  @Input() isPrefixBold: boolean = false;
  @Input() fontSize: string = '18px';
  @Input() fontHeight: string = '22px';
  @Input() isFontBold: boolean = true;
  @Input() suffixSize: string = '12px';
  @Input() suffixHeight: string = '16px';
  @Input() isSuffixBold: boolean = false;
  prefixAmount: string;
  suffixAmount: string;

  ngOnInit() {
    this.initPrefixSuffix();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['amount']) {
      this.initPrefixSuffix();
    }
  }

  initPrefixSuffix() {
    let priceNumber = Number(this.amount).toFixed(3);
    let priceString = priceNumber.toString();
    this.prefixAmount = priceString.slice(0, -1)
    this.suffixAmount = priceString.slice(-1);
  }
}
