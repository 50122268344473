import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'hf-checkbox',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './hf-checkbox.component.html',
  styleUrl: './hf-checkbox.component.scss'
})
export class HfCheckboxComponent {

  @Input() inputValue: FormControl = new FormControl('');
  @Input() inputLabel: string = '';
  @Input() inputId: string = 'defaultId';
  @Input() disabled: boolean = false;
  @Input() skipTab: boolean = false;
  
  ngOnInit() {
    if (this.disabled) {
      this.inputValue.disable();
    }
  }
}
