import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { environment } from '../environments/environment';
import { Meta } from '@angular/platform-browser';
import { SharedService } from './shared/services/shared-service.service';
import { CommonService } from './shared/services/common.config';
import { PopupComponent } from './shared/components/popup/popup.component';
import { ToasterComponent } from './shared/components/toaster/toaster.component';
import { AppService } from '@monorepo/utils';
import { HfToastComponent, HfToastService } from '@monorepo/ui';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, PopupComponent, ToasterComponent, HfToastComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  constructor(
    public sharedService: SharedService,
    public config: CommonService,
    private meta: Meta,
    private toastService: HfToastService,
    public appService: AppService,
  ) {
  }

  ngOnInit() {
    if (environment.production) {
      this.meta.updateTag({
        name: 'robots',
        content: 'all',
      });
    } else {
      this.meta.updateTag({
        name: 'robots',
        content: 'noindex, nofollow',
      });
    }
    this.appService.setPlatform(environment.is_local);
    if (this.appService.isBrowserRendering) {
      window.addEventListener('offline', () => {
        this.toastService.toastMessage.next({ title: `Please check your internet connection.`, msg: 'Please check your internet connection. If this issue persists, please contact us at (631) 735 - 5645', type: 'error' });
      });
    }
  }
}
