import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
export interface ToastMessage {
  title: string;
  msg: string;
  type: 'success' | 'warning' | 'error' | 'info';
}

@Injectable({
  providedIn: 'root'
})

export class HfToastService {

  public toastMessage = new BehaviorSubject<ToastMessage>({ title: '', msg: '', type: 'success' });
  toastMessage$: Observable<any> = this.toastMessage.asObservable();

}
