import { Injectable } from '@angular/core';
import { NativeAudio } from '@capacitor-community/native-audio';
import { AppService } from './app.service';

@Injectable({
    providedIn: 'root',
})
export class NativeAudioService {

    defaultAssetId: string = `notificationSound`;
    defaultAssetPath: string = `assets/sounds/notification.wav`;

    constructor(
        private appService: AppService
    ) { }

    // Preload the MP3 file
    preloadAudio(assetId: string = this.defaultAssetId, assetPath: string = this.defaultAssetPath) {
        NativeAudio.preload({
            assetId: assetId,
            assetPath: this.appService.isApp ? `public/${assetPath}` : assetPath,
            isUrl: false // Indicates that the file is stored locally in the app
        }).then(() => {
            console.log('MP3 file loaded successfully');
        }).catch((error) => {
            console.error('Error loading MP3 file:', error);
        });
    }

    // Play the MP3 file
    playAudio(assetId: string = this.defaultAssetId, time: number = 0) {
        NativeAudio.play({
            assetId: assetId,
            time: time
        }).then(() => {
            console.log('Audio played successfully');
        }).catch((error) => {
            console.error('Error playing audio:', error);
        });
    }

    // Stop the MP3 file
    stopAudio(assetId: string = this.defaultAssetId) {
        NativeAudio.stop({
            assetId: assetId
        }).then(() => {
            console.log('Audio stopped successfully');
        }).catch((error) => {
            console.error('Error stopping audio:', error);
        });
    }

    // Unload the MP3 file
    unloadAudio(assetId: string = this.defaultAssetId) {
        NativeAudio.unload({
            assetId: assetId
        }).then(() => {
            console.log('MP3 file unloaded successfully');
        }).catch((error) => {
            console.error('Error unloading MP3 file:', error);
        });
    }
}
