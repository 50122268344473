@if(showModel){
<div class="hf-model-outline">
    <div class="model-close-mask" (click)="closeModel()"></div>
    <div class="hf-model" [ngClass]="{'hf-model-extralarge': modelSize === 'extralarge',
    'hf-model-large': modelSize === 'large', 
    'hf-model-medium': modelSize === 'medium', 
    'hf-model-small': modelSize === 'small', 
    'hf-model-extrasmall': modelSize === 'extrasmall'}">
        <div class="hf-model-title" [ngClass]="{'hf-model-title-extralarge': modelSize === 'extralarge',
        'hf-model-title-large': modelSize === 'large', 
        'hf-model-title-medium': modelSize === 'medium', 
        'hf-model-title-small': modelSize === 'small', 
        'hf-model-title-extrasmall': modelSize === 'extrasmall'}">
            <span class="hf-dark-font">{{modelTitle}}</span>
            <img (click)="closeModel()" src='/assets/icons/model-close.svg' alt="Model close icon"
                title="Model close icon" style="cursor: pointer;"/>
        </div>
        <div class="hf-model-content">
            <ng-content></ng-content>
        </div>
    </div>
</div>
}