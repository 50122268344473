import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  // we always have one item in the cart
  // we store the cart in the local storage, android SharedPreferences and UserDefaults on iOS
  // However to deal with async await, we use variable cache for fast data fetching
  store: any = {};

  async initStore() {
    this.store = {};
    const keys = await Preferences.keys();
    for (let key of keys['keys']) {
      let value = (await Preferences.get({ key: key })).value;
      if (value) { // not null or undefined
        try {
          this.store[key] = JSON.parse(value);
        } catch (error) {
          this.store[key] = value;
        }
      }
    }
  }

  async addValueToStore(key: string, value: any) {
    this.store[key] = value;
    await Preferences.set({
      key: key,
      value: JSON.stringify(value),
    });
  }

  async getValueFromStore(key: string, callback: (value: any) => void) {
    if (this.store.hasOwnProperty(key)) {
      callback(this.store[key]);
    } else {
      const { value } = await Preferences.get({ key });
      if (value) {
        try {
          callback(JSON.parse(value));
        } catch (error) {
          callback(value);
        }
      } else {
        callback(null);
      }
    }
  }

  async checkKeyInStore(key: string, callback: (exists: boolean) => void) {
    if (this.store.hasOwnProperty(key)) {
      callback(true);
    } else {
      try {
        const keys = await Preferences.keys();
        const index = keys['keys'].indexOf(key);
        if (index !== -1) {
          try {
            const { value } = await Preferences.get({ key: keys['keys'][index] });
            if (value) {
              try {
                this.store[key] = JSON.parse(value);
              } catch (error) {
                this.store[key] = value;
              }
            } else {
              this.store[key] = null;
            }
            callback(true);
          } catch {
            callback(false);
          }
        } else {
          callback(false);
        }
      } catch {
        callback(false);
      }
    }
  }

  async deleteKeyFromStore(key: string) {
    if (this.store.hasOwnProperty(key)) {
      delete this.store[key];
    }
    await Preferences.remove({ key: key });
  }

  async deleteAll() {
    this.store = {};
    const keys = await Preferences.keys();
    for (let key of keys['keys']) {
      await Preferences.remove({ key: key });
    }
  }
}