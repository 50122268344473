import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { Editor, NgxEditorModule, Toolbar } from 'ngx-editor';

@Component({
  selector: 'hf-editor',
  standalone: true,
  imports: [NgxEditorModule, ReactiveFormsModule],
  templateUrl: './hf-editor.component.html',
  styleUrl: './hf-editor.component.scss'
})

export class HfEditorComponent implements OnInit, OnDestroy {

  editor: Editor;
  @Input() editorFormGroup: any;
  @Input() editorControlName: any;
  @Input() toolbar: Toolbar = [
    ['bold', 'italic'],
    ['underline', 'strike'],
    ['code', 'blockquote'],
    ['ordered_list', 'bullet_list'],
    [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
    ['text_color', 'background_color'],
    ['align_left', 'align_center', 'align_right', 'align_justify'],
  ];
  @Input() placeholder: string = 'Type here...';

  ngOnInit() {
    if (this.editorFormGroup === undefined || this.editorFormGroup === null) {
      throw new Error('editorFormGroup is required, Please provide formGroup to editor component');
    }
    if (this.editorControlName === undefined || this.editorControlName === null) {
      throw new Error('editorControlName is required, Please provide formControlName to editor component');
    }
    this.editor = new Editor();
  }

  ngOnDestroy() {
    this.editor.destroy();
  }
}
