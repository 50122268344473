import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'hf-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './hf-card.component.html',
  styleUrl: './hf-card.component.scss'
})
export class HfCardComponent {

  @Input() cardStyle: 'primary' | 'secondary' | 'tertiary' = 'primary';
  @Input() cardTitleSize: 'extralarge' | 'large' | 'medium' | 'small' | 'extrasmall' = 'large';
  @Input() cardTitle: string = '';

}
