import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { jwtDecode } from 'jwt-decode';
import { Cookie, CookieService, LoginResponse, CheckEmailInfo, LoginInfo } from '..';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  isSetAuthCalled: boolean = false;
  project: string = '';
  apiUrl: string = '';
  apiVersion: string = '';
  emailKey = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  cryptojsKey: string = '';
  cryptojsIv: string = '';
  isUserLoggedIn: boolean = false;
  userEmail: string = '';
  userWalletBalance: string = '';
  companyCookieDomain: string = '';

  public checkEmailInfo = new BehaviorSubject<CheckEmailInfo>({ email: '', isVaild: false });
  checkEmailInfo$: Observable<any> = this.checkEmailInfo.asObservable();

  public isLoginDone = new BehaviorSubject<LoginInfo>({ isLoginDone: false, loginResponse: null });
  isLoginDone$: Observable<any> = this.isLoginDone.asObservable();

  constructor(
    private cookieService: CookieService
  ) { }

  setAuth(project: string, apiUrl: string, apiVersion: string, cryptojsKey: string, cryptojsIv: string) {
    this.project = project;
    this.isSetAuthCalled = true;
    this.apiUrl = apiUrl;
    this.apiVersion = apiVersion;
    this.cryptojsIv = cryptojsIv;
    this.cryptojsKey = cryptojsKey;
  }

  getEncryptedValue(value: string) {
    const key = CryptoJS.enc.Base64.parse(this.cryptojsKey);
    const iv = CryptoJS.enc.Base64.parse(this.cryptojsIv);

    const encrypted = CryptoJS.AES.encrypt(value, key, {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });

    const encryptedString = encrypted.toString();
    return encryptedString;
  }

  private setCookie(name: string, value: string, domain?: string, path?: string, expires?: string) {
    return this.cookieService.set(name, value, domain, path, expires);
  }

  private getCookie(name: string): string {
    return this.cookieService.get(name);
  }

  decodeToken(token: string) {
    return jwtDecode(token);
  }

  processLoginResponse(res: LoginResponse, domain?: string, path?: string, expires?: string) {
    this.setCookie(Cookie.AUTH_TOKEN, res.token, domain, path, expires);
    this.setCookie(Cookie.PRIVACY_CONFIRMED, `${res.privacyConfirmed}`, domain, path, expires);
    this.setCookie(Cookie.REFRESH_TOKEN, res.refreshToken, domain, path, expires);
    if (res.companyName) {
      this.setCookie(Cookie.COMPANY_NAME, res.companyName, domain, path, expires);
    }
    if (res.companyTruckLogo) {
      let companyTruckLogo = JSON.stringify(res.companyTruckLogo);
      this.setCookie(Cookie.COMPANY_TRUCK, companyTruckLogo, domain, path, expires);
    }
    let decoded: any = this.decodeToken(res.token);
    this.setCookie(Cookie.COMPANY_ID, decoded.CompanyId, domain, path, expires);
    this.setCookie(Cookie.USER_EMAIL, decoded.Email, domain, path, expires);
    this.setCookie(Cookie.USER_ID, decoded.UserId, domain, path, expires);
    this.setCookie(Cookie.USER_ROLE, decoded.Role, domain, path, expires);
    this.setCookie(Cookie.USER_NAME, decoded.UserName, domain, path, expires);
    this.setCookie(Cookie.FIRST_NAME, decoded.FirstName, domain, path, expires);
    this.setCookie(Cookie.LAST_NAME, decoded.LastName, domain, path, expires);
    this.setCookie(Cookie.TOKEN_EXPIRATION, (decoded.exp * 1000).toString(), domain, path, expires);
    if (res.address) {
      this.setCookie(Cookie.USER_ADDRESSS, JSON.stringify(this.getAddressObject(res.address)), domain, path, expires);
    }

    if (res.walletBalance !== null && res.walletBalance !== undefined) {
      this.setCookie(Cookie.WALLET_BALANCE, JSON.stringify(res.walletBalance), domain, path, expires);
    }
    this.makeUserCache();
  }

  getAddressObject(addresses: any) {
    return addresses.map(({ id, deliveryZIP, deliveryStreet1, deliveryNickName, deliveryCity, deliveryState, deliveryStreet2, customerTaxType, oilTaxexempt, base64 }: any) => ({
      id,
      deliveryZIP,
      deliveryStreet1,
      deliveryNickName,
      deliveryCity,
      deliveryState,
      deliveryStreet2,
      customerTaxType,
      oilTaxexempt,
      base64
    }));
  }

  checkUserIsLoggedIn() {
    try {
      const authToken = this.getCookie(Cookie.AUTH_TOKEN);
      let decoded: any = jwtDecode(authToken);
      if (decoded.hasOwnProperty("UserId")) {
        this.isUserLoggedIn = true;
        this.makeUserCache();
      } else {
        this.isUserLoggedIn = false;
      }
    } catch (error) {
      this.isUserLoggedIn = false;
    }
  }

  makeUserCache() {
    this.isUserLoggedIn = true;
    this.userEmail = this.getCookie(Cookie.USER_EMAIL);
    this.userWalletBalance = this.getCookie(Cookie.WALLET_BALANCE);
  }

  updateWallerBalenceCookie() {
    this.userWalletBalance = this.getCookie(Cookie.WALLET_BALANCE);
  }

  generateRandomPassword(length = 8) {
    const Allowed = {
      Uppers: 'QWERTYUIOPASDFGHJKLZXCVBNM',
      Lowers: 'qwertyuiopasdfghjklzxcvbnm',
      Numbers: '1234567890',
      Symbols: '!@#$%^&*',
    };
    const getRandomCharFromString = (str: string) =>
      str.charAt(Math.floor(Math.random() * str.length));
    let pwd = '';
    pwd += getRandomCharFromString(Allowed.Uppers);
    pwd += getRandomCharFromString(Allowed.Lowers).repeat(2);
    pwd += getRandomCharFromString(Allowed.Numbers);
    pwd += getRandomCharFromString(Allowed.Symbols);
    for (let i = pwd.length; i < length; i++)
      pwd += getRandomCharFromString(Object.values(Allowed).join(''));
    return pwd;
  }

  clearUserCache() {
    this.isUserLoggedIn = false;
    this.userEmail = '';
  }
}
