import { Injectable } from "@angular/core";
import { AppService } from "./app.service";

// eslint-disable-next-line no-var


@Injectable({
    providedIn: 'root'
})

export class AnalyticsService {

    constructor(
        public appService: AppService
    ) {
    }

    // this method is used to track events for google analytics

    trackEvent(eventName: string, eventData: Object) {
        if (this.appService.isBrowserRendering) {
            let dataLayer = (window as any).dataLayer || [];
            dataLayer.push({ 'event': eventName, ...eventData });
        }
    }

}