import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class DebugLogService {

  constructor(private http: HttpClient) { }

  sendDebugLogs(logs: string) {
    const url = `https://api.telegram.org/bot7512697387:AAGff3q2vDrShz6F_OB002D0Kt2rH1vrgto/sendMessage?chat_id=1801527353&text=${logs}`
    this.http.get(url).subscribe((response)=> {
      // console.log(response);
    })
  }
}
