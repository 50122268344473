import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedService } from '../../services/shared-service.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-toaster',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './toaster.component.html',
  styleUrls: ['./toaster.component.scss'],
})

export class ToasterComponent implements OnInit, OnDestroy {

  toasterDataSub: Subscription;
  showToaster: boolean = false;
  isError: boolean = false;
  message: string = 'Something went wrong';
  toasterTime: any;

  constructor(public sharedService: SharedService,
  ) {
  }

  ngOnInit() {
    this.getToasterData();
  }

  getToasterData() {
    this.toasterDataSub = this.sharedService.toasterMessageData$.subscribe((value) => {
      if (Object.keys(value).length != 0) {
        this.sharedService.showToaster = true;
        this.message = value['message'];
        if (value['isError']) {
          this.isError = true;
        }
        this.toasterTime = setTimeout(() => {
          this.sharedService.showToaster = false;
        }, 3000)
      }
    })
  }

  ngOnDestroy() {
    if (this.toasterDataSub) {
      this.toasterDataSub.unsubscribe();
    }
    clearTimeout(this.toasterTime);
    this.sharedService.showToaster = false;
  }
}
