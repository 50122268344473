import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class HeatfleetHeaderService {

  isMenuOpen = false;
  isPageMenuOpen = false;
  isSecure: boolean = false;
  isCompany: boolean = false;
  companyName: string = 'Heat fleet';
  urls: any;
  clientUrl: string = '';
  isIntialized: boolean = false;
  fullLogoUrl: string = '';
  iconLogoUrl: string = '';
  nameLogoUrl: string = '';

  initHeatFleetHeader(project: 'secure' | 'seo', companyName: string, urls: any, client_url: string, full_logo_url: string, icon_logo_url: string, name_logo_url: string) {
    if (project === 'secure') {
      this.isSecure = true;
    } else {
      this.isSecure = false;
    }
    this.urls = urls;
    this.clientUrl = client_url;
    this.isIntialized = true;
    this.fullLogoUrl = full_logo_url;
    this.iconLogoUrl = icon_logo_url;
    this.nameLogoUrl = name_logo_url;
    if (companyName !== '') {
      this.isCompany = true;
      this.companyName = companyName;
    } else {
      this.isCompany = false;
      this.companyName = 'Heat fleet';
    }
  }

  toggleMenu() {
    this.isMenuOpen = !this.isMenuOpen;
  }

  togglePageMenu() {
    this.isPageMenuOpen = !this.isPageMenuOpen;
  }

}
