import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST, RESPONSE } from './express.token';
import { isPlatformServer } from '@angular/common';

@Injectable({
    providedIn: 'root'
})

export class CookieService {
    constructor(
        @Optional() @Inject(RESPONSE) private response: any,
        @Optional() @Inject(REQUEST) private request: any,
        @Inject(PLATFORM_ID) private platformId: any
    ) {
    }

    get(name: string): string {
        let cookie = '';

        if (isPlatformServer(this.platformId)) {
            cookie = this.request?.cookies[name];
            if (cookie) {
                return cookie;
            } else {
                return '';
            }
        } else {
            const cookieName = name + '=';
            const decodedCookie = decodeURIComponent(document.cookie);
            const cookieArray = decodedCookie.split(';');
            for (let i = 0; i < cookieArray.length; i++) {
                let cookie = cookieArray[i];
                while (cookie.charAt(0) === ' ') {
                    cookie = cookie.substring(1);
                }
                if (cookie.indexOf(cookieName) === 0) {
                    return cookie.substring(cookieName.length, cookie.length);
                }
            }
            return '';
        }
    }

    set(name: string, value: any, domain?: string, path: string = '/', expires?: string) {
        if (isPlatformServer(this.platformId)) {
            let cookieSettings: any = {};
            if (domain) {
                cookieSettings['domain'] = domain;
            }
            if (expires) {
                cookieSettings['expires'] = expires;
            }
            this.response?.cookie(name, value, cookieSettings);
        } else {
            let cookieString = `${name}=${value}`;
            if (domain) {
                cookieString += `; domain=${domain}`;
            }
            if (expires) {
                cookieString += `; expires=${expires}`;
            }
            cookieString += `; path=${path}`;
            document.cookie = cookieString;
        }
    }

    delete(name: string, domain?: string, path: string = '/') {
        if (isPlatformServer(this.platformId)) {
            this.response?.clearCookie(name, { domain, path });
        } else {
            document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT` +
                (domain ? `;domain=${domain}` : '') +
                `;path=${path}`;
        }
    }

    deleteAll(domain?: string, path: string = '/') {
        if (isPlatformServer(this.platformId)) {
            this.response?.clearCookie();
        } else {
            const cookies = document.cookie.split(";");
            for (let i = 0; i < cookies.length; i++) {
                let cookie = cookies[i];
                let eqPos = cookie.indexOf("=");
                let name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
                document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT" +
                    (domain ? ";domain=" + domain : "") +
                    ";path=" + path;
            }
        }
    }

}
