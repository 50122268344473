import { Injectable } from '@angular/core';
import { Camera, CameraResultType, CameraSource, Photo } from '@capacitor/camera';

@Injectable()
export class CameraService {
  constructor() { }

  async takePhoto(): Promise<string | null> {
    try {
      const photo: Photo = await Camera.getPhoto({
        quality: 90,
        allowEditing: false,
        resultType: CameraResultType.Base64,  // Get the photo as a Base64-encoded string
        source: CameraSource.Camera
      });

      // Return the base64 string with a data URL prefix
      return `data:image/jpeg;base64,${photo.base64String}` || null;
    } catch (error) {
      console.error('Camera error:', error);
      return null;
    }
  }
}
