<div class="house-location">
  <div class="top">
    <div class="horizontal">
      <div class="radio-mini-container">
        <hf-radio [radioValue]="4" inputName="tankLocation" [inputValue]="inputValue" radioSize="medium"></hf-radio>
      </div>
      <div class="radio-mini-container">
        <hf-radio [radioValue]="5" inputName="tankLocation" [inputValue]="inputValue" radioSize="medium"></hf-radio>
      </div>
    </div>
  </div>
  <div class="middle">
    <div class="left">
      <div class="vertical">
        <div class="radio-mini-container">
          <hf-radio [radioValue]="3" inputName="tankLocation" [inputValue]="inputValue" radioSize="medium"></hf-radio>
        </div>
        <div class="radio-mini-container">
          <hf-radio [radioValue]="2" inputName="tankLocation" [inputValue]="inputValue" radioSize="medium"></hf-radio>
        </div>
      </div>
    </div>
    <div class="center" [ngClass]="{ border: imgUrl === '' ? true: false }">
      @if (imgUrl === '') {
      Front of the House
      } @else {
      <img [src]="imgUrl">
      }
    </div>
    <div class="right">
      <div class="vertical">
        <div class="radio-mini-container">
          <hf-radio [radioValue]="1" inputName="tankLocation" [inputValue]="inputValue" radioSize="medium"></hf-radio>
        </div>
        <div class="radio-mini-container">
          <hf-radio [radioValue]="8" inputName="tankLocation" [inputValue]="inputValue" radioSize="medium"></hf-radio>
        </div>
      </div>
    </div>
  </div>
  <div class="bottom">
    <div class="horizontal">
      <div class="radio-mini-container">
        <hf-radio [radioValue]="6" inputName="tankLocation" [inputValue]="inputValue" radioSize="medium"></hf-radio>
      </div>
      <div class="radio-mini-container">
        <hf-radio [radioValue]="7" inputName="tankLocation" [inputValue]="inputValue" radioSize="medium"></hf-radio>
      </div>
    </div>
  </div>
</div>
