import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'hf-button',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './hf-button.component.html',
  styleUrl: './hf-button.component.scss'
})

export class HfButtonComponent {

  @Input() type: 'button' | 'submit' | 'reset' = 'button';
  @Input() buttonLabelType: 'text' | 'custom' = 'text';
  @Input() labelText: string = 'Button';
  @Input() buttonType: 'standard' | 'icononly' = 'standard';
  @Input() buttonStyle: 'primary' | 'secondary' | 'tertiary' = 'primary';
  @Input() buttonSize: 'extralarge' | 'large' | 'medium' | 'small' | 'extrasmall' = 'extralarge';
  @Input() iconUrl: string = '';
  @Input() iconAlt: string = '';
  @Input() iconTitle: string = '';
  @Input() iconStyle: 'noicon' | 'iconleft' | 'iconright' = 'noicon';
  @Input() gtmId: string = '';
  @Input() isDisabled: boolean = false;
  @Input() colorTheme: 'info' | 'danger' = 'info';
  isClicked: boolean = false;
}
