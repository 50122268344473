import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl } from '@angular/forms';
import { HfRadioComponent } from '../hf-radio/hf-radio.component';

@Component({
  selector: 'hf-pipe-location',
  standalone: true,
  imports: [CommonModule, HfRadioComponent],
  templateUrl: './hf-pipe-location.component.html',
  styleUrl: './hf-pipe-location.component.scss',
})
export class HfPipeLocationComponent {
  @Input() inputValue: FormControl = new FormControl();
  @Input() imgUrl: string = '';
}
