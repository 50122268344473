import { CommonModule } from '@angular/common';
import { Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { inputMsgType } from '../../utils/interface';

@Component({
  selector: 'hf-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './hf-input.component.html',
  styleUrl: './hf-input.component.scss'
})

export class HfInputComponent implements OnChanges {

  @Input() inputSize: 'small' | 'medium' = 'medium';
  @Input() inputValue: FormControl = new FormControl('');
  @Input() inputStyle: 'noicon' | 'iconleft' | 'iconright' = 'noicon';
  @Input() inputLabel: string = '';
  @Input() inputType: 'email' | 'text' | 'password' | 'number' | 'textarea' | 'tel' | 'custom' = 'text';
  @Input() inputPlaceholder: string = '';
  @Input() iconUrl: string = '';
  @Input() iconAlt: string = '';
  @Input() iconTitle: string = '';
  @Input() iconWord: string = 'D';
  @Input() isPassword: boolean = false;
  @Input() inputDescription: string = '';
  @Input() descriptionType: inputMsgType = '';
  @Input() rows: number = 5;
  @Input() isDisabled: boolean = false;
  @Input() focus: boolean = false;

  @ViewChild('inputElement', { static: false }) inputElement: ElementRef;

  @Output() focusOut: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() focusIn: EventEmitter<Event> = new EventEmitter<Event>();
  isShowPassword: boolean = false;
  @Output() toogleShowPassword = new EventEmitter<boolean>();

  ngOnChanges(changes: SimpleChanges) {
    if (changes['isDisabled'] && this.inputValue) {
      if (changes['isDisabled'].currentValue) {
        this.inputValue.disable();
      } else {
        this.inputValue.enable();
      }
    }

    if (changes['focus']) {
      if (changes['focus'].currentValue) {
        setTimeout(() => {
          if (this.inputElement) {
            this.inputElement.nativeElement.focus();
          }
        }, 1);
      } else {
        setTimeout(() => {
          if (this.inputElement) {
            this.inputElement.nativeElement.blur();
          }
        }, 1);
      }
    }
  }

  showPassword() {
    this.isShowPassword = !this.isShowPassword;
    if (this.isShowPassword) {
      this.inputType = 'text';
      this.toogleShowPassword.emit(true);
    } else {
      this.inputType = 'password';
      this.toogleShowPassword.emit(false);
    }
  }

  onFocusOut($event: Event) {
    this.focusOut.emit($event);
  }

  onFocusIn($event: Event) {
    this.focusIn.emit($event);
  }
}
