<div class="hf-input" [ngClass]="{'hf-input-medium': inputSize === 'medium',
    'hf-input-small': inputSize === 'small'}">
    @if (inputStyle === 'iconleft') {
    <div style="width: 24px; height: 24px;">
        @if (iconUrl !== ''){
        <img [src]='iconUrl' [alt]="iconAlt" [title]="iconTitle" />
        } @else {
        <div class="hf-default-logo hf-dark-font">{{iconWord}}</div>
        }
    </div>
    }
    <div class="hf-input-content">
        @if (inputLabel !== '') {
        <label class="hf-input-title hf-input-title-text">{{inputLabel}}</label>
        }
        @if(inputType === 'textarea'){
        <textarea class="hf-input-value" [ngClass]="{'hf-input-medium-value-text': inputSize === 'medium',
        'hf-input-small-value-text': inputSize === 'small'}" [placeholder]="inputPlaceholder"
            [formControl]="inputValue" [rows]="rows"></textarea>
        } @else if(inputType === 'custom'){
        <ng-content></ng-content>
        } @else {
        <input #inputElement [type]="inputType" class="hf-input-value" [ngClass]="{'hf-input-medium-value-text': inputSize === 'medium',
        'hf-input-small-value-text': inputSize === 'small'}" [placeholder]="inputPlaceholder"
            [formControl]="inputValue" (blur)="onFocusOut($event)" (focus)="onFocusIn($event)">
        }
    </div>
    @if (isPassword) {
    <div class="hf-password-icon" (click)="showPassword()">
        @if(isShowPassword) {
        <img src='/assets/icons/open-eye.svg' alt="hide password icon" title="hide password icon" />
        } @else {
        <img src='/assets/icons/close-eye.svg' alt="show password icon" title="show password icon" />
        }
    </div>
    }
    @if (inputStyle === 'iconright') {
    <div style="width: 24px; height: 24px;">
        @if (iconUrl !== ''){
        <img [src]='iconUrl' [alt]="iconAlt" [title]="iconTitle" />
        } @else {
        <div class="hf-default-logo hf-dark-font">{{iconWord}}</div>
        }
    </div>
    }
</div>
@if(descriptionType !== '') {
<div class="hf-message">
    @if(descriptionType === 'error') {
    <img src='/assets/icons/error.svg' alt="error message icon" title="error message icon" />
    } @else if(descriptionType === 'warning') {
    <img src='/assets/icons/warning.svg' alt="warning message icon" title="warning message icon" />
    }@else if(descriptionType === 'loading') {
    <img src='/assets/icons/loader.svg' alt="loader message icon" title="loader message icon" />
    } @else if(descriptionType === 'success') {
    <img src='/assets/icons/success.svg' alt="success message icon" title="success message icon" />
    }
    <p class="hf-input-description-text">{{inputDescription}}</p>
</div>
}