import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'hf-radio',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './hf-radio.component.html',
  styleUrl: './hf-radio.component.scss'
})

export class HfRadioComponent {
  @Input() inputValue: FormControl = new FormControl('');
  @Input() inputLabel: string;
  @Input() inputName: string = '';
  @Input() radioValue: string | number = '';
  // @Input() inputId: string = '';
  @Input() isDisabled: boolean = false;
  @Input() radioSize: 'large' | 'medium' | 'small' = 'large';
}