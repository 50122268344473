import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'hf-model',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './hf-model.component.html',
  styleUrl: './hf-model.component.scss'
})

export class HfModelComponent implements OnChanges {

  @Input() showModel: boolean = false;
  @Input() modelSize: 'extralarge' | 'large' | 'medium' | 'small' | 'extrasmall' = 'extrasmall';
  @Input() modelTitle: string = '';

  @Output() modelClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  body: any;

  ngOnChanges(changes: SimpleChanges) {
    if (changes['showModel'] && changes['showModel'].currentValue === true) {
      this.body = document.getElementsByTagName('body')[0];
      this.body.classList.add('hf-disable-scroll');
      this.modelClosed.emit(false);
    } else {
      if (this.body && this.body.classList) {
        this.body.classList.remove('hf-disable-scroll');
      }
    }
  }

  closeModel() {
    this.showModel = false;
    this.body.classList.remove('hf-disable-scroll');
    this.modelClosed.emit(true);
  }
}
