import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { inputMsgType } from '../../utils/interface';

@Component({
  selector: 'hf-select',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './hf-select.component.html',
  styleUrl: './hf-select.component.scss'
})
export class HfSelectComponent {
  @Input() inputValue: FormControl = new FormControl('');
  @Input() selectDescription: string = '';
  @Input() descriptionType: inputMsgType = '';
}
