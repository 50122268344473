<select [formControl]="inputValue" style="width: 100%;">
    <ng-content></ng-content>
</select>
@if(descriptionType !== '') {
<div class="hf-message">
    @if(descriptionType === 'error') {
    <img src='lib/assets/icons/error.svg' alt="error message icon" title="error message icon" />
    } @else if(descriptionType === 'warning') {
    <img src='lib/assets/icons/warning.svg' alt="warning message icon" title="warning message icon" />
    }@else if(descriptionType === 'loading') {
    <img src='lib/assets/icons/loader.svg' alt="loader message icon" title="loader message icon" />
    } @else if(descriptionType === 'success') {
    <img src='lib/assets/icons/success.svg' alt="success message icon" title="success message icon" />
    }
    <p class="hf-input-description-text">{{selectDescription}}</p>
</div>
}