import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SharedService {

  currentUrl: string = '';
  isHomePage: boolean = false;  // is page is home
  isMainPage: boolean = false;  // is page is heating oil, oil prices etc.
  isSubPage: boolean = false; // not home or heating oil, oil prices etc.
  isStatePage: boolean = false;
  isTownPage: boolean = false;
  isCompanyPage: boolean = false;
  isOtherPage: boolean = false;
  isStatesSelectionPage: boolean = false;
  isCountiesSelectionPage: boolean = false;
  isRegionsSelectionPage: boolean = false;
  isCitiesSelectionPage: boolean = false;
  isCitiesSelectionSubPage: boolean = false;
  isStateCitiesSelectionPage: boolean = false; // Ex url. oil_companies/CT-Connecticut-Cities.html (3 slug and with Cities.html)
  // isHeatingOilPage: boolean = false; // true if url end with heating_oil.html
  // isOilCompaniesPage: boolean = false; // true if url end with oil_companies.html
  isHeatingOilPricesPage: boolean = false; // true if url end with heating_oil_prices.html
  // isOilDeliveryPage: boolean = false; // true if url end with oil_delivery.html
  // isHeatingOilSubPage: boolean = false; // true if url end with heating_oil/state, town, etc...
  // isOilCompaniesSubPage: boolean = false; // true if url end with  oil_companies/state, town, etc...
  isHeatingOilPricesSubPage: boolean = false; // true if url end with heating_oil_prices/state, town, etc...
  // isOilDeliverySubPage: boolean = false; // true if url end with oil_delivery/state, town, etc...
  originalPageSlug: string = 'heating_oil'; // Ex. heating_oil, heating_oil.html
  pageSlug: string = 'heating oil'; // Ex. heating oil
  pageSlugInUpperCase: string = 'HEATING OIL'; //Ex. HEATING OIL
  pageSlugInCapitalFirst: string = 'Heating oil'; //Ex. Heating oil
  pageSlugInCapitalAllFirst: string = 'Heating Oil'; //Ex. Heating Oil
  locationSlug: string = ''; // Ex. NY-New_York
  locationSlugArray: Array<string> = []; // Ex. ['NY', 'New_York']
  location: string = '';  // Ex. New York (location means name of the current state or town, if url has town name than it will be town name not state name)
  locationInUpperCase: string = ''; // Ex. NEW YORK
  locationInCapitalFirst: string = ''; //Ex. New york
  locationInCapitalAllFirst: string = ''; //Ex. New York
  // locationWithRegionCapitalFirst: string = ''; //Ex. Saco, Southern maine
  // locationWithRegionCapitalAllFirst: string = ''; //Ex. Saco, Southern Maine
  companyName: string = '';
  companyNameInUpperCase: string = '';
  companyNameInCapitalFirst: string = '';
  companyNameInCapitalAllFirst: string = '';
  pageLinkPrefix: string = 'heating_oil' // this will use when genrate link and you need to subdirectory in route Ex. heating_oil, oil_companies etc
  segmentOfUrl: Array<string> = [];

  // other usefull variable
  isMarketPlace: boolean = false;
  showPopup: boolean = false;
  showToaster: boolean = false;
  isUserLogin: boolean = false;
  regionList: Array<string> = ["Long Island", "South Shore Massachusetts", "Western Mass", "Cape Cod", "Southern Maine", "Mid Coast Maine", "Central Maine"];
  emailKey = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  public isUrlChange = new BehaviorSubject<boolean>(false);
  isUrlChange$: Observable<boolean> = this.isUrlChange.asObservable();

  public popUpMessageData = new BehaviorSubject<any>({});
  popUpMessageData$: Observable<any> = this.popUpMessageData.asObservable();

  public toasterMessageData = new BehaviorSubject<any>({});
  toasterMessageData$: Observable<any> = this.toasterMessageData.asObservable();

  public popUpZipSearch = new BehaviorSubject<any>({});
  popUpZipSearch$: Observable<any> = this.popUpZipSearch.asObservable();

  public townsApiData = new BehaviorSubject<any>({});
  townsApiData$: Observable<any> = this.townsApiData.asObservable();

  public getPageDetailsApiData = new BehaviorSubject<any>({});
  getPageDetailsApiData$: Observable<any> = this.getPageDetailsApiData.asObservable();

  public stateHashApiData = new BehaviorSubject<any>({});
  stateHashApiData$: Observable<any> = this.stateHashApiData.asObservable();

  public nearestLocationApiData = new BehaviorSubject<any>({});
  nearestLocationApiData$: Observable<any> = this.nearestLocationApiData.asObservable();

  public townHashApiData = new BehaviorSubject<any>({});
  townHashApiData$: Observable<any> = this.townHashApiData.asObservable();

  public townCompaniesHashApiData = new BehaviorSubject<any>({});
  townCompaniesHashApiData$: Observable<any> = this.townCompaniesHashApiData.asObservable();

  public weatherHistoryApiData = new BehaviorSubject<any>({});
  weatherHistoryApiData$: Observable<any> = this.weatherHistoryApiData.asObservable();

  public companyProfileByHashApiData = new BehaviorSubject<any>({});
  companyProfileByHashApiData$: Observable<any> = this.companyProfileByHashApiData.asObservable();

  public pieChartData = new BehaviorSubject<any>([]);
  pieChartData$: Observable<any> = this.pieChartData.asObservable();

  public lineChartDataForUsage = new BehaviorSubject<any>([]);
  lineChartDataForUsage$: Observable<any> = this.lineChartDataForUsage.asObservable();

  public newsDetails = new BehaviorSubject<any>({});
  newsDetails$: Observable<any> = this.newsDetails.asObservable();

  public countryPriceData = new BehaviorSubject<any>({});
  countryPriceData$: Observable<any> = this.countryPriceData.asObservable();

  public nyserdaPriceData = new BehaviorSubject<any>({});
  nyserdaPriceData$: Observable<any> = this.nyserdaPriceData.asObservable();

  public selectedAddress = new BehaviorSubject<any>({});
  selectedAddress$: Observable<any> = this.selectedAddress.asObservable();

  async resetPageType(): Promise<boolean> {
    this.currentUrl = '';
    this.isHomePage = false;
    this.isMainPage = false;
    this.isSubPage = false;
    this.isStatePage = false;
    this.isTownPage = false;
    this.isCompanyPage = false;
    this.isOtherPage = false;
    this.isStatesSelectionPage = false;
    this.isCountiesSelectionPage = false;
    this.isRegionsSelectionPage = false;
    this.isCitiesSelectionPage = false;
    // this.isHeatingOilPage = false;
    // this.isOilCompaniesPage = false;
    this.isHeatingOilPricesPage = false;
    // this.isOilDeliveryPage = false;
    // this.isHeatingOilSubPage = false;
    // this.isOilCompaniesSubPage = false;
    this.isHeatingOilPricesSubPage = false;
    // this.isOilDeliverySubPage = false;
    this.isCitiesSelectionSubPage = false;
    this.isStateCitiesSelectionPage = false;
    this.originalPageSlug = 'heating_oil';
    this.pageLinkPrefix = 'heating_oil';
    this.pageSlug = 'heating oil';
    this.pageSlugInCapitalAllFirst = 'Heating Oil';
    this.pageSlugInCapitalFirst = 'Heating oil';
    this.pageSlugInUpperCase = 'HEATING OIL';
    this.location = '';
    this.locationInUpperCase = '';
    this.companyName = '';
    this.locationSlug = '';
    this.locationInCapitalFirst = '';
    this.locationInCapitalAllFirst = '';
    // this.locationWithRegionCapitalFirst = '';
    // this.locationWithRegionCapitalAllFirst = '';
    this.companyNameInUpperCase = '';
    this.companyNameInCapitalFirst = '';
    this.companyNameInCapitalAllFirst = '';
    this.locationSlugArray = [];
    this.segmentOfUrl = [];
    this.isUrlChange.next(false);
    return true;
  }

  capitalizeWords(string: string) {
    return string.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  }

  capitalFirstLetter(string: string) {
    //Input: 'heating oil' --> Output: 'Heating oil'
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
  }

  //Input: 'new york' --> Output: 'New_york' // use for making router link on a tag
  convertToCustomCase(str: string) {
    // Split the string into words
    let words = str.replace(' ', "_");

    // Capitalize the first letter of the entire string
    let firstLetterUpper = words.charAt(0).toUpperCase() + words.slice(1);

    return firstLetterUpper;
  }

  HashState = new Map([
    ['CT', 'Connecticut'],
    ['DE', 'Delaware'],
    ['OH', 'Ohio'],
    ['VA', 'Virginia'],
    ['MA', 'Massachusetts'],
    ['NY', 'New York'],
    ['NH', 'New Hampshire'],
    ['VT', 'Vermont'],
    ['RI', 'Rhode Island'],
    ['ME', 'Maine'],
    ['NJ', 'New Jersey'],
    ['PA', 'Pennsylvania'],
    ['MD', 'Maryland'],
    ['LI', 'Long Island'],
  ]);

  getStateNameById(stateId: string): string {
    if (this.HashState.has(stateId)) {
      // Return the corresponding state name
      return this.HashState.get(stateId);
    } else {
      // Return an error message or handle the case when the state code is not found
      return '404';
    }
  }

  // Call this function after making other navigation links
  // It will take link into input and return a valid link (EX. Removing space from url to prevent %25 in serch console error)
  urlValidator(url: string): string {
    url = url.replace(/ /g, '_'); // Replace all spaces with underscores
    url = url.replace(/_+/g, '_'); // Replace multiple underscores with a single underscore
    url = url.replace('King_And_Queen', 'King_and_Queen');
    url = url.replace('Isle_Of_Wight', 'Isle_of_Wight')
    return url;
  }

}
