import { DavosEnvironment } from "../app/shared/config/environment.interface";

const local: DavosEnvironment = {
  is_local: true,
  production: false,
  api_url: 'https://dev-api.heatfleet.com/api/',
  image_url: 'http:/localhost:5000/uploads/',
  api_version: '1.0',
  api_key: 'oiwe43raiasdl4kha6sdf123',
  api_mock_url: 'http://localhost:3000/',
  tokenization_key: '24x5CN-Y34yun-9cy3V7-t5MAZ2',
  token: '',
  isMobile: false,
  vendor_url: 'https://dev.systemstar.com/',
  temp_api_url: 'https://heatingoilfinder-dev-api-hf.sigmanet.info/',
  client_url: 'https://dev.heatfleet.com/',
  secure_url: 'https://dev-secure.heatfleet.com/',
  paypalClientId:
    'AcFQTHo45inLdsXIgxTqiPRQiHyHJWynxvJTnSjJWVuMc6eyuNTP_edPjghjHAhC2gK8dbPjhACWwdIx',
}

const dev: DavosEnvironment = {
  is_local: false,
  production: false,
  api_url: 'https://dev-api.heatfleet.com/api/',
  image_url: 'https://heatfleet.com/uploads/',
  api_version: '1.0',
  api_key: 'oiwe43raiasdl4kha6sdf123',
  api_mock_url: 'http://localhost:3000/',
  tokenization_key: '24x5CN-Y34yun-9cy3V7-t5MAZ2',
  token: '',
  isMobile: false,
  vendor_url: 'https://dev.systemstar.com/',
  temp_api_url: 'https://heatingoilfinder-dev-api-hf.sigmanet.info/',
  client_url: 'https://dev.heatfleet.com/',
  secure_url: 'https://dev-secure.heatfleet.com/',
  paypalClientId:
    'AcFQTHo45inLdsXIgxTqiPRQiHyHJWynxvJTnSjJWVuMc6eyuNTP_edPjghjHAhC2gK8dbPjhACWwdIx',
}

const uat: DavosEnvironment = {
  is_local: false,
  production: false,
  api_url: 'https://demo-api.heatfleet.com/api/',
  image_url: 'https://b.heatfleet.com/uploads/',
  api_version: '1.0',
  api_key: 'oiwe43raiasdl4kha6sdf123',
  api_mock_url: 'http://localhost:3000/',
  tokenization_key: '24x5CN-Y34yun-9cy3V7-t5MAZ2',
  token: '',
  isMobile: false,
  vendor_url: 'https://demo.systemstar.com/',
  temp_api_url: 'https://heatingoilfinder-dev-api-hf.sigmanet.info/',
  client_url: 'https://demo.heatfleet.com/',
  secure_url: 'https://demo-secure.heatfleet.com/',
  paypalClientId:
    'AVd9gVKUYrf1BAU0M2gRAebZcl4IH7G_FZ4_HsW_YGD1WXHagnH7J5EGvS7GS2KNY680njuGYmEH7rAR',
}

const main: DavosEnvironment = {
  is_local: false,
  production: true,
  api_url: 'https://api.heatfleet.com/api/',
  image_url: 'http://vendor.davos.test.erise.hu/uploads/',
  api_version: '1.0',
  api_key: 'oiwe43raiasdl4kha6sdf123',
  api_mock_url: 'http://localhost:3000/',
  tokenization_key: '922Z9E-q4QWUc-2j2968-c2Fx28',
  token: '',
  isMobile: false,
  vendor_url: 'https://systemstar.com/',
  temp_api_url: 'https://api.heatingoilfinder.com/',
  client_url: 'https://heatfleet.com/',
  secure_url: 'https://secure.heatfleet.com/',
  paypalClientId:
    'AVd9gVKUYrf1BAU0M2gRAebZcl4IH7G_FZ4_HsW_YGD1WXHagnH7J5EGvS7GS2KNY680njuGYmEH7rAR',
}

export const environment = dev;