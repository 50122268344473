import { isPlatformServer } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { REQUEST, RESPONSE } from './express.token';

@Injectable({
  providedIn: 'root'
})

export class ServerService {
  private _response: any;
  private _request: any;
  isServerRendering: boolean = false;
  isBrowserRendering: boolean = false;

  constructor(
    @Optional() @Inject(RESPONSE) private responseContext: any,
    @Optional() @Inject(REQUEST) private requestContext: any,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    if (isPlatformServer(this.platformId)) {
      this._response = this.responseContext;
      this._request = this.requestContext;
    }
  }

  get response(): any {
    return this._response;
  }

  get request(): any {
    return this._request;
  }
}
